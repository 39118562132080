import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  TextField,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Dialog,
  DialogContent,
  DialogTitle
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End

import DuplicatedetectionController, {
  Props,
  configJSON,
} from "./DuplicatedetectionController";

export default class Duplicatedetection extends DuplicatedetectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Container style={{ width: "500px", marginTop: '10px' }}>
        <Paper style={{ padding: 16 }}>
          <Typography variant="h5" component="h3">
            Duplicate Detection
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              label="First Name"
              data-test-id="firstname"
              margin="normal"
              value={this.state.firstName}
              fullWidth
              onChange={(event) => { this.handleFirstNameChange(event.target.value) }}
              variant="outlined"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              onChange={(event) => { this.handleLastNameChange(event.target.value) }}
              value={this.state.lastName}
              variant="outlined"
              data-test-id="lastname"
            />
            <TextField
              fullWidth
              margin="normal"
              data-test-id="emailaddress"
              label="Email"
              onChange={(event) => { this.handleEmailAddressChange(event.target.value) }}
              variant="outlined"
              type="email"
              value={this.state.emailAddress}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.createDatApi()}
              style={{ marginTop: 16 }}
              fullWidth
              data-test-id="submit-btn"
            >
              Submit
            </Button>
            <Typography data-test-id="text-message-test-id" variant="h6" component="h4">
              {this.state.successMessage}
            </Typography>
          </form>
        </Paper>
      </Container>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>FirstName</TableCell>
                <TableCell align="center">LastName</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Duplicate Data</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>            
              {this.state.showDataTable.length > 0 && this.state.showDataTable.map((item: {
                id: string,
                type: string,
                attributes: {
                  first_name: string
                  last_name: string,
                  email: string,
                  is_duplicate: string
                }
              }, index: number) => {
                return (<>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.attributes.first_name}
                    </TableCell>
                    <TableCell align="center">{item.attributes.last_name}</TableCell>
                    <TableCell align="center">{item.attributes.email}</TableCell>
                    <TableCell align="center">{item.attributes.is_duplicate.toString()}</TableCell>
                    <TableCell align="center">
                      <Button variant="contained" color="primary" data-test-id="showButton" style={{ marginRight: "12px" }} onClick={() => this.showData(item.id)}>
                        Show
                      </Button>
                      <Button variant="contained" color="primary" data-test-id="editButton" style={{ marginRight: "12px" }} onClick={() => this.handleEditButtonClick (item.id)}>
                        Edit
                      </Button>
                      <Button variant="contained" color="secondary" data-test-id="deleteButton" onClick={() => this.deleteDataApiCall(item.id)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </>)
              })}
          </Table>
        </TableContainer>
        <Dialog aria-labelledby="customized-dialog-title" open={this.state.open}>
        <IconButton aria-label="close" style={{justifyContent:"right"}} >
          <CloseIcon data-test-id="closeButton" onClick={()=> this.handleClose()}/>
        </IconButton>
        <DialogTitle id="customized-dialog-title">
          Information
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
           FirstName: {this.state.showFirstName}
          </Typography>
          <Typography gutterBottom>
           LastName: {this.state.showLastName}
          </Typography>
          <Typography gutterBottom>
           Email: {this.state.showEmailAddress}
          </Typography>
          <Typography gutterBottom>
          Duplicate: {this.state.showDuplicate.toString()}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
