import React from "react";
// Customizable Area Start
import { 
  Container,
  Paper,
  Typography,
  TextField,
  Button

} from "@material-ui/core";

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
        <Container style={{ width: "500px", marginTop: '10px' }}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h5" component="h3">
              Sign Up
            </Typography>
            <form noValidate autoComplete="off">
            <TextField
                label="Full Name"
                fullWidth
                margin="normal"
                data-test-id="fullname"
                value={this.state.fullName}
                variant="outlined"
                onChange={(event) => { this.handleFullNameChange(event.target.value) }}
              />
              <Typography  style={{color:"red"}}>{this.state.fullNameError}</Typography>
              <TextField
                label="First Name"
                fullWidth
                data-test-id="firstname"
                margin="normal"
                variant="outlined"
                value={this.state.firstName}
                onChange={(event) => { this.handleFirstNameChange(event.target.value) }}
              />
              <TextField
                label="Last Name"
                fullWidth
                margin="normal"
                data-test-id="lastname"
                value={this.state.lastName}
                variant="outlined"
                onChange={(event) => { this.handleLastNameChange(event.target.value) }}
              />
              <TextField
                label="Email"
                fullWidth
                data-test-id="emailaddress"
                margin="normal"
                variant="outlined"
                value={this.state.email}
                type="email"
                onChange={(event) => { this.handleEmailAddressChange(event.target.value) }}
              />
              <Typography style={{color:"red"}}>{this.state.emailError}</Typography>
              <TextField
                label="Password"
                fullWidth
                margin="normal"
                data-test-id="password"
                value={this.state.password}
                variant="outlined"
                type="password"
                onChange={(event) => { this.handlePasswordChange(event.target.value) }}
              />
              <Typography  style={{color:"red"}}>{this.state.passwordError}</Typography>
              <Button
                onClick={() => this.signUpApi()}
                variant="contained"
                color="primary"
                fullWidth
                data-test-id="submit-btn"
                style={{ marginTop: 16 }}
              >
                signup
              </Button>
            </form>
          </Paper>
        </Container>
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End