import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Paper,
  TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <Container style={{ width: "500px", marginTop: '10px' }}>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h5" component="h3">
              Login
            </Typography>
            <form noValidate autoComplete="off">
              <TextField
                label="Email"
                fullWidth
                data-test-id="emailaddress"
                margin="normal"
                variant="outlined"
                value={this.state.email}
                type="email"
                onChange={(event) => { this.handleEmailAddressChange(event.target.value) }}
              />
              <TextField
                label="Password"
                fullWidth
                margin="normal"
                data-test-id="password"
                value={this.state.password}
                variant="outlined"
                type="password"
                onChange={(event) => { this.handlePasswordChange(event.target.value) }}
              />
              <Typography style={{color:"red"}}>{this.state.error}</Typography>
              <Button
              onClick={()=>this.loginApi()}
                variant="contained"
                color="primary"
                fullWidth
                data-test-id="submit-btn"
                style={{ marginTop: 16 }}
              >
                login
              </Button>
            </form>
          </Paper>
        </Container>
       // Customizable Area End
    );
  }
}
