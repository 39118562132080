import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  firstName: string;
  lastName: string;
  emailAddress: string;
  fullPhoneNumber: string;
  password: string;
  userName: string;
  successMessage: string;
  newRecordId: number | null;
  token: string;
  showDataTable: {
    id: string;
    type: string;
    attributes: {
      first_name: string;
      last_name: string;
      email: string;
      is_duplicate: string;
    };
    errors: {
      first_name: [string];
      last_name: [string];
      email: [string];
    };
  }[];
  editData: {
    first_name: string;
    last_name: string;
    email: string;
    is_duplicate: boolean;
  }[];
  editRecordId: string | null | number;
  open: boolean;
  showFirstName: string;
  showLastName: string;
  showEmailAddress: string;
  showDuplicate: string;
  mode: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DuplicatedetectionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: string = "";
  updateAccountApiCallId: string = "";
  indexDataApiCallId: string = "";
  showDataApiCallId: string = ""
  deleteAccountApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      firstName: "",
      lastName: "",
      emailAddress: "",
      newRecordId :null,
      fullPhoneNumber: "",
      showDataTable:[],
      editData:[],
      password: "",
      userName: "",
      successMessage: "",
      token:"",
      editRecordId : null,
      open:false,
      showFirstName: "",
      showLastName: "",
      showEmailAddress: "",
      showDuplicate:"",
      mode: ""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.createAccountApiCallId) {      
      if(responseJson && !responseJson.data.attributes.errors){
        if (this.state.showDataTable !== undefined) {
          this.setState({
            showDataTable: [...this.state.showDataTable, responseJson.data],
            newRecordId : responseJson.id
          })
        } 
        this.setState({ firstName: '', lastName: '', emailAddress: '' });
        this.indexDataApi()
      }
    } else if (apiRequestCallId === this.updateAccountApiCallId) {
      if(responseJson && !responseJson.data.attributes.errors){
        this.indexDataApi()
        this.setState({firstName:'',lastName:'',emailAddress:'',editRecordId :null})
      }
    }else if (apiRequestCallId === this.indexDataApiCallId) {
      this.setState({ showDataTable: responseJson.data})
    }else if (apiRequestCallId === this.deleteAccountApiCallId) {
      this.indexDataApi()
    }else if (apiRequestCallId === this.showDataApiCallId) {
      this.handleShowData(responseJson)
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    let refToken = await getStorageData("token")
    this.setState({token:refToken})
    this.indexDataApi()
  }

  handleFirstNameChange = (value: string) => {
    this.setState({ firstName: value })
  }

  handleLastNameChange = (value: string) => {
    this.setState({ lastName: value })
  }

  handleEmailAddressChange = (value: string) => {
    this.setState({ emailAddress: value })
  }


  createDatApi = () => {
    if (this.state.editRecordId  === null) {
      
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: this.state.token
      };
      const creatreApiData = {
        "client_details":
        {
          "first_name": this.state.firstName,
          "last_name": this.state.lastName,
          "email": this.state.emailAddress
        }
      }


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createAccountEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(creatreApiData)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);


      return true;
    }
    else {
      this.editDataApiCall ()
    }
  }
  
  handleEditButtonClick = (recordIdToEdit: string) => {
    this.showDataApi(recordIdToEdit, "edit")
    this.setState({ editRecordId : recordIdToEdit })
  }
  
  deleteDataApiCall  = (deleteId:string) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAccountEndPoint}${deleteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  } 

  editDataApiCall  = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token:this.state.token
    };
    const editApiData = {
      "client_details":
          {
              "first_name": this.state.firstName,
              "last_name":this.state.lastName,
              "email": this.state.emailAddress,
          }
  } 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteAccountEndPoint}${this.state.editRecordId }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(editApiData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  indexDataApi = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token:this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.indexDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIndexDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  showData = (recordIdToView: string) => {
    this.setState({open:true})
    this.showDataApi(recordIdToView, "view")
  }

  handleClose = () => {
    this.setState({open:false})
  };

  showDataApi = (recordIdToView: string, mode: string) => {
    this.setState({mode})
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token:this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showAccountEndPoint}${recordIdToView}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleShowData = (responseJson:{data :{id: string,
    type: string,
    attributes: {
      first_name: string
      last_name: string,
      email: string,
      is_duplicate: string
    }
    errors: {
      first_name: [
        string
      ]
      last_name: [
        string
      ]
      email: [
        string
      ]
    }}}) =>{
    const editData = responseJson.data
    if (editData) {
      if(this.state.mode === "view") {
        this.setState({
          showFirstName: editData.attributes.first_name,
          showLastName: editData.attributes.last_name,
          showEmailAddress: editData.attributes.email,
          showDuplicate:editData.attributes.is_duplicate,
          mode: ""
        })
      } else {
        this.setState({
          firstName: editData.attributes.first_name,
          lastName: editData.attributes.last_name,
          emailAddress: editData.attributes.email,
          mode: ""
        })
      }
    }
  }
  // Customizable Area End
}
